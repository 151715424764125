import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Row,
} from 'reactstrap';
import Referral_Large from '../assets/img/referral.png';
import Referral_Small from '../assets/img/referral_small.png';
function Referral() {
	const state = useSelector(state => state.web3Reducer);

	return (
		<React.Fragment className='referral-container'>
			<Container className='mt-5'>
				<h2 className='section__heading text-uppercase'>
					referral program
				</h2>
				<hr className='underline__hr' />
				<Card className='referral__card mt-4 py-3'>
					<CardBody>
						<div className='text-center d-none d-md-block'>
							<div className='d-flex justify-content-center'>
								<div className='level_1'>
									<p className='withdraw__label mb-0 fs-15 text-black'>
									{(state?.userData?.userRefferals &&state?.userData?.userRefferals[0]) || 0}
									</p>
									<Label className='withdraw__label fs-15 text-black'>
										Level 1
									</Label>
									<p className='level_number'>5%</p>
								</div>
								<div className='level_2'>
									<p className='withdraw__label mb-0 fs-15 text-black'>
									{(state?.userData?.userRefferals &&state?.userData?.userRefferals[1]) || 0}
									</p>
									<Label className='withdraw__label fs-15 text-black'>
										Level 2
									</Label>
									<p className='level_number'>3%</p>
								</div>
								<div className='level_3'>
									<p className='withdraw__label mb-0 fs-15 text-black'>
										{(state?.userData?.userRefferals &&state?.userData?.userRefferals[2]) || 0}
									</p>
									<Label className='withdraw__label fs-15 text-black'>
										Level 3
									</Label>
									<p className='level_number'>2%</p>
								</div>
							</div>
							<img
								src={Referral_Large}
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='text-center d-block d-md-none'>
							<div className='d-flex justify-content-center'>
								<div className='level_1'>
									<p className='withdraw__label mb-0 fs-15 text-black'>
										{(state?.userData?.userRefferals &&state?.userData?.userRefferals[0]) || 0}		
									</p>
									<Label className='withdraw__label fs-15 text-black'>
										Level 1
									</Label>
									<p className='level_number'>5%</p>
								</div>
								<div className='level_2'>
									<p className='withdraw__label mb-0 fs-15 text-black'>
									{(state?.userData?.userRefferals &&state?.userData?.userRefferals[1]) || 0}
									</p>
									<Label className='withdraw__label fs-15 text-black'>
										Level 2
									</Label>
									<p className='level_number'>3%</p>
								</div>
								<div className='level_3'>
									<p className='withdraw__label mb-0 fs-15 text-black'>
									{(state?.userData?.userRefferals &&state?.userData?.userRefferals[2]) || 0}
									</p>
									<Label className='withdraw__label fs-15 text-black'>
										Level 3
									</Label>
									<p className='level_number'>2%</p>
								</div>
							</div>
							<img src={Referral_Small} alt='' />
						</div>
						<Row className='justify-content-around mt-5'>
							<Col lg='1' className='d-none d-lg-block'></Col>
							<Col md='3'>
								<Label className='withdraw__label fs-15 text-black'>
									Total Referral Earned
								</Label>
								<p className='text-black withdraw__label fs-24 fw-500'>
									{state?.userData?.totalBonus?.toFixed(2) ||
										0.0}
								</p>
							</Col>
							<Col md='3'>
								<Label className='withdraw__label fs-15 text-black'>
									Total Referral Withdrawn
								</Label>
								<p className='text-black withdraw__label fs-24 fw-500'>
									{state?.userData?.bonusWithdrawn?.toFixed(
										2
									) || 0.0}
								</p>
							</Col>
							<Col md='3'>
							{/* <Label className='withdraw__label fs-15 text-black'>
									Hold Bonus
								</Label>
								<p className='text-black withdraw__label fs-24 fw-500'>
									{state?.userData?.holdBonus?.toFixed(
										2
									) || 0.0}%
								</p> */}
							</Col>
						</Row>
						<div className='mt-4 d-block d-md-flex justify-content-center '>
							<Label className='withdraw__label text-nowrap align-self-center fs-15 text-black'>
								Your Referral Link
							</Label>
							{/* <div className='d-flex'> */}
							<Input
								className='custom__input mx-0 mx-md-3 referral__input'
								value={`https://avaxworld.finance/?ref=${state?.userAddress}`}
							/>
							<Button
								className='btn__round mt-3 mt-md-0 px-4 '
								onClick={() => {
									const text = `https://avaxword.finance/?ref=${state?.userAddress}`;
									navigator.clipboard.writeText(text);
									toast.success('Copied successfully');
									// alert('Text copied');
								}}
							>
								Copy
							</Button>
							{/* </div> */}
						</div>
					</CardBody>
				</Card>
			</Container>
		</React.Fragment>
	);
}

export default Referral;
