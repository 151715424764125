import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { Col, Container, Row } from 'reactstrap';
import TimerCircle from '../assets/img/timer_circle.png';
function Hero() {
	const [active, setActive] = useState(true);

	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		// Render a countdown
		if (completed) {
			setActive(false);
			return (<></>)
		} else {
			return (
				<>
					<div className='d-flex  justify-content-between px-2 px-lg-5  mb-5 mt-4'>
						<div className='text-center'>
							<p className='timer-label'>DAYS</p>
							<p className='timer-time timer-time-1'>
								{days < 10 ? 0 + '' + days : days}
							</p>
							<img
								src={TimerCircle}
								className='timer-circle'
								alt=''
							/>
						</div>
						<div className='text-center'>
							<p className='timer-label'>HOURS</p>
							<p className='timer-time timer-time-2'>
								{hours < 10 ? 0 + '' + hours : hours}
							</p>
							<img
								src={TimerCircle}
								className='timer-circle'
								alt=''
							/>
						</div>
						<div className='text-center'>
							<p className='timer-label'>MINUTS</p>
							<p className='timer-time timer-time-3'>
								{' '}
								{minutes < 10 ? 0 + '' + minutes : minutes}
							</p>
							<img
								src={TimerCircle}
								className='timer-circle'
								alt=''
							/>
						</div>
						<div className='text-center'>
							<p className='timer-label'> SECONDS</p>
							<p className='timer-time timer-time-4'>
								{' '}
								{seconds < 10 ? 0 + '' + seconds : seconds}
							</p>
							<img
								src={TimerCircle}
								className='timer-circle'
								alt=''
							/>
						</div>
					</div>
				</>
			);
		}
	};

	return (
		<>
			<Container
				className={`text-center p-4 mt-5 mb-5 position-relative custom-content ${
					!active && 'd-none'
				}`}
			>
				<p className='launch_in pb-4 '>Launching In</p>
				<Countdown
					className='fs-24 mt-0 fw-800'
					date={1648645200000}
					renderer={renderer}
				></Countdown>
			</Container>
			<Container className='hero__container' fluid>
				<Container className='py-3 py-md-5'>
					<Row>
						<Col md='6'>
							<p className='hero__heading'>
								<strong className='fs-36'>Earn up to</strong>{' '}
								<br />{' '}
								<strong className='text__orange fs-64'>
									20%
								</strong>{' '}
								<strong className='fs-64'>Daily</strong> <br />{' '}
								<span className='fw-300 fs-32'>
									on your Staked AVAX Token !
								</span>
							</p>
						</Col>
						<Col md='6' className='mt-4 mt-md-0'>
							<p className='hero__desc'>
								Avaxworld is a hybrid ROI Dapp project that is
								built on the Avalanche Network. The developers
								behind the project chose the Avalanche network
								aims to create a sustainable community in
								cheaper transaction fee for matured investors.
								As ROI Dapps are normally a high-risk
								investment, in offering a high returns by
								increasing capital volume and higher APY, a
								community-driven network will be beneficial for
								investors. <br />
								<br /> <br /> This is the best time to put your
								AVAX into yield returns and gain good ROI. This
								is a community driven project. We encourage
								every member of the community to re-invest a
								small percentage of their gains so as to sustain
								the life-span of the contract.
							</p>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
}

export default Hero;
