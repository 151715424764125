import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Input,
	Label,
	Row,
} from 'reactstrap';
import Lock from '../assets/img/lock.png';
import UnLock from '../assets/img/unlock.png';
import { investHandler } from '../utils/web3-helper';

function Packages() {
	const state = useSelector(state => state?.web3Reducer);

	const [plan1Value, setPlan1Value] = useState(0);
	const [plan2Value, setPlan2Value] = useState(0);
	const [plan3Value, setPlan3Value] = useState(0);
	const [plan4Value, setPlan4Value] = useState(0);

	const plan1Investment = async () => {
		try {
			if (plan1Value < 0.05) {
				toast.warn('Cannot invest less than 0.05');
				// alert('Cannot invest less than 0.05');
				return;
			}
			await investHandler(0, plan1Value);
			setPlan1Value(0);
		} catch (err) {
			console.log(err);
		}
	};

	const plan2Investment = async () => {
		try {
			if (plan2Value < 0.05) {
				toast.warn('Cannot invest less than 0.05');
				// alert('Cannot invest less than 0.05');
				return;
			}
			await investHandler(1, plan2Value);
			setPlan2Value(0);
		} catch (err) {
			console.log(err);
		}
	};

	const plan3Investment = async () => {
		try {
			if (plan3Value < 0.05) {
				toast.warn('Cannot invest less than 0.05');
				// alert('Cannot invest less than 0.05');
				return;
			}
			await investHandler(2, plan3Value);
			setPlan3Value(0);
		} catch (err) {
			console.log(err);
		}
	};

	const plan4Investment = async () => {
		try {
			if (plan4Value < 0.05) {
				toast.warn('Cannot invest less than 0.05');
				// alert('Cannot invest less than 0.05');
				return;
			}
			await investHandler(3, plan4Value);
			setPlan4Value(0);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Container className='mt-5'>
			<h2 className='section__heading'>PACKAGES</h2>
			<hr className='underline__hr' />
			<Row className='mt-4'>
				<Col lg='4' md='6'>
					<Card className='package__card'>
						<CardHeader>
							<div className='d-flex'>
								<Label className='plan__label'>PLAN 1</Label>
								<img
									src={UnLock}
									alt='UnLock'
									height='30px'
									width='30px'
									className='ms-auto'
								/>
							</div>
						</CardHeader>
						<div className='text-center'>
							<Button className='custom__parent__round'>
								<Button className='custom__child__round'>
									<span className='days__number'>14</span>{' '}
									<span className='days'>DAYS</span>
									<p className='days__desc'>
										In 14 days you will get{' '}
										<span className='text__yellow'>
											{(
												(plan1Value || 0) *
												(state?.plan1Percentage / 100) *
												14
											).toFixed(2) || 0.0}
										</span>
									</p>
								</Button>
							</Button>
						</div>
						<CardBody className='package__card__body'>
							<div className='d-flex'>
								<div>
									<Label className='withdraw__label fs-15 text-black'>
										Daily Earnings
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
										{state?.plan1Percentage.toFixed(2)}%
									</p>
								</div>
								<div className='ms-auto'>
									<Label className='withdraw__label fs-15 text-black'>
										Total ROI
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
										{(state?.plan1Percentage * 14).toFixed(
											2
										)}
										%
									</p>
								</div>
							</div>
							<Label className='withdraw__label mt-3 fs-15 text-black'>
								Enter Amount AVAX
							</Label>
							<Input
								className='custom__input'
								type='number'
								min={0}
								value={plan1Value}
								onChange={e => {
									setPlan1Value(parseFloat(e.target.value));
								}}
							/>
							<div className='text-center mt-3'>
								<Button
									className='btn__round px-5'
									onClick={plan1Investment}
								>
									DEPOSIT
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col lg='4' md='6' className='mt-4 mt-md-0'>
					<Card className='package__card'>
						<CardHeader>
							<div className='d-flex'>
								<Label className='plan__label'>PLAN 2</Label>
								<img
									src={UnLock}
									alt='UnLock'
									height='30px'
									width='30px'
									className='ms-auto'
								/>
							</div>
						</CardHeader>
						<div className='text-center'>
							<Button className='custom__parent__round'>
								<Button className='custom__child__round'>
									<span className='days__number'>28</span>{' '}
									{''}
									<span className='days'>DAYS</span>
									<p className='days__desc'>
										In 28 days you will get{' '}
										<span className='text__yellow'>
											{(
												(plan2Value || 0) *
												(state?.plan2Percentage / 100) *
												28
											)?.toFixed(2) || 0.0}
										</span>
									</p>
								</Button>
							</Button>
						</div>
						<CardBody className='package__card__body'>
							<div className='d-flex'>
								<div>
									<Label className='withdraw__label fs-15 text-black'>
										Daily Earnings
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
										{state?.plan2Percentage.toFixed(2)}%
									</p>
								</div>
								<div className='ms-auto'>
									<Label className='withdraw__label fs-15 text-black'>
										Total ROI
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
										{(state?.plan2Percentage * 28).toFixed(
											2
										)}
										%
									</p>
								</div>
							</div>
							<Label className='withdraw__label mt-3 fs-15 text-black'>
								Enter Amount AVAX
							</Label>
							<Input
								className='custom__input'
								type='number'
								min={0}
								value={plan2Value}
								onChange={e => {
									setPlan2Value(parseFloat(e.target.value));
								}}
							/>
							<div className='text-center mt-3'>
								<Button
									className='btn__round px-5'
									onClick={plan2Investment}
								>
									DEPOSIT
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row className='mt-5'>
				<Col lg='4' md='6'>
					<Card className='package__card'>
						<CardHeader>
							<div className='d-flex'>
								<Label className='plan__label'>PLAN 3</Label>
								<img
									src={Lock}
									alt='Lock'
									height='30px'
									width='30px'
									className='ms-auto'
								/>
							</div>
						</CardHeader>
						<div className='text-center'>
							<Button className='custom__parent__round'>
								<Button className='custom__child__round'>
									<span className='days__number'>14</span>{' '}
									<span className='days'>DAYS</span>
									<p className='days__desc'>
										In 14 days you will get{' '}
										<span className='text__yellow'>
											{(
												(plan3Value || 0) *
												((state?.plan3Percentage * 28) / 100)
											).toFixed(2) || 0.0}
										</span>
									</p>
								</Button>
							</Button>
						</div>
						<CardBody className='package__card__body'>
							<div className='d-flex'>
								<div>
									<Label className='withdraw__label fs-15 text-black'>
										Daily Earnings
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
										{state?.plan3Percentage.toFixed(2)}%
									</p>
								</div>
								<div className='ms-auto'>
									<Label className='withdraw__label fs-15 text-black'>
										Total ROI
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
										{(state?.plan3Percentage * 28).toFixed(
											2
										)}
										%
									</p>
								</div>
							</div>
							<Label className='withdraw__label mt-3 fs-15 text-black'>
								Enter Amount AVAX
							</Label>
							<Input
								className='custom__input'
								type='number'
								min={0}
								value={plan3Value}
								onChange={e => {
									setPlan3Value(parseFloat(e.target.value));
								}}
							/>
							<div className='text-center mt-3'>
								<Button
									className='btn__round px-5'
									onClick={plan3Investment}
								>
									DEPOSIT
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col lg='4' md='6' className='mt-4 mt-md-0'>
					<Card className='package__card'>
						<CardHeader>
							<div className='d-flex'>
								<Label className='plan__label'>PLAN 4</Label>
								<img
									src={Lock}
									alt='Lock'
									height='30px'
									width='30px'
									className='ms-auto'
								/>
							</div>
						</CardHeader>
						<div className='text-center'>
							<Button className='custom__parent__round'>
								<Button className='custom__child__round'>
									<span className='days__number'>28</span>{' '}
									{''}
									<span className='days'>DAYS</span>
									<p className='days__desc'>
										In 28 days you will get{' '}
										<span className='text__yellow'>
										{(
												(plan4Value || 0) *
												((state?.plan4Percentage * 190) / 100)
											).toFixed(2) || 0.0}
										</span>
									</p>
								</Button>
							</Button>
						</div>
						<CardBody className='package__card__body'>
							<div className='d-flex'>
								<div>
									<Label className='withdraw__label fs-15 text-black'>
										Daily Earnings
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
										{state?.plan4Percentage.toFixed(2)}%
									</p>
								</div>
								<div className='ms-auto'>
									<Label className='withdraw__label fs-15 text-black'>
										Total ROI
									</Label>
									<p className='text-black withdraw__label fs-24 fw-500'>
									{(state?.plan4Percentage * 190).toFixed(
											2
										)}
										%
									</p>
								</div>
							</div>
							<Label className='withdraw__label mt-3 fs-15 text-black'>
								Enter Amount AVAX
							</Label>
							<Input
								className='custom__input'
								type='number'
								min={0}
								value={plan4Value}
								onChange={e => {
									setPlan4Value(parseFloat(e.target.value));
								}}
							/>
							<div className='text-center mt-3'>
								<Button
									className='btn__round px-5'
									onClick={plan4Investment}
								>
									DEPOSIT
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Packages;
