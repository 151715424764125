import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Navbar,
	NavbarBrand,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Container,
	Button,
} from 'reactstrap';
import { connectWallet } from '../utils/web3-helper';
import { configEnv } from '../utils/configEnv'

function TopNav() {
	const [open, setOpen] = useState(false);
	const state = useSelector(state => state.web3Reducer);

	const openToggle = () => {
		setOpen(!open);
	};

	const connectWalletHandler = async () => {
		await connectWallet();
	};

	return (
		<Container className='top_nav'>
			<Navbar expand='md' className='custom__nav'>
				<NavbarBrand
					href='/'
					className={`brand px-3 px-md-0 d-none d-md-block`}
				>
					<span className='text__yellow'>avax</span>
					<span className='text__gray'>world.finance</span>
				</NavbarBrand>
				<NavbarBrand
					href='/'
					className={`brand px-3 px-md-0 d-block d-md-none ${
						open && 'd-none'
					}`}
				>
					<span className='text__yellow'>avax</span>
					<span className='text__gray'>world.finance</span>
				</NavbarBrand>
				<i
					className={`fa fa-bars fa-2x text__yellow px-3 px-md-0 d-block d-md-none pointer ${
						open && 'd-none'
					}`}
					onClick={openToggle}
				></i>
				<Collapse isOpen={open} navbar className='custom__collapse'>
					<div className='d-flex'>
						<i
							className='fa fa-times ms-auto fa-2x  px-3 px-md-0 d-block d-md-none pointer'
							onClick={openToggle}
						></i>
					</div>
					<Nav className='ms-auto mt-3' navbar>
						<NavItem className='d-none d-md-block'>
							{state?.userAddress ? (
								<Button className='btn__round'>
									{state?.userAddress?.replace(
										state?.userAddress?.substring(5, 38),
										'***'
									)}
								</Button>
							) : (
								<Button
									className='btn__round'
									onClick={() => connectWalletHandler()}
								>
									Connect Wallet
								</Button>
							)}
						</NavItem>
						<NavItem className='d-block d-md-none'>
							<NavLink href='#dashboard'>DASHBOARD</NavLink>
						</NavItem>
						<NavItem className='d-block d-md-none mt-2'>
							<NavLink href='https://hazecrypto.net/audit/AvaxWorld' target="_blank">AUDIT</NavLink>
						</NavItem>
						<NavItem className='d-block d-md-none mt-2'>
							<NavLink href='https://t.me/+-HLhOWHmpNAyYTlh' target="_blank">TELEGRAM</NavLink>
						</NavItem>
						<NavItem className='d-block d-md-none mt-2'>
							<NavLink target='_blank' href={configEnv.AVAX_CONTRACT_ADDRESS_URL}>CONTRACT</NavLink>
						</NavItem>
						<NavItem className='d-block d-md-none mt-2'>
							<NavLink 
								href='https://medium.com/@avaxfinance/avaxworld-finance-earn-up-to-20-daily-on-your-staked-avax-token-da4310d09f99'
								target="_blank"
							>DOCUMENTATION</NavLink>
						</NavItem>
						<NavItem className='d-block d-md-none mt-2'>
							{state?.userAddress ? (
								<Button block className='btn__round'>
									{state?.userAddress?.replace(
										state?.userAddress?.substring(5, 38),
										'***'
									)}
								</Button>
							) : (
								<Button
									className='btn__round '
									block
									onClick={() => connectWalletHandler()}
								>
									Connect Wallet
								</Button>
							)}
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</Container>
	);
}

export default TopNav;
