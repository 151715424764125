import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	userAddress: '',
	plan1Percentage: 9,
	plan2Percentage: 7,
	plan3Percentage: 10,
	plan4Percentage: 12,
	userData: '',
	totalStaked: 0,
	avaxPrice: 0,
	userDeposits:"",
	userTotalDeposits:0,
	isLoaderOpen:false,
	loaderMessage:"",
};

const web3Slice = createSlice({
	name: 'web3Slice',
	initialState,
	reducers: {
		setWalletAddress(state, action) {
			state.userAddress = action.payload;
		},
		setPercentages(state, action) {
			const {
				plan1Percentage,
				plan2Percentage,
				plan3Percentage,
				plan4Percentage,
			} = action.payload;

			state.plan1Percentage = plan1Percentage;
			state.plan2Percentage = plan2Percentage;
			state.plan3Percentage = plan3Percentage;
			state.plan4Percentage = plan4Percentage;
		},
		setUserData(state, action) {
			state.userData = action.payload;
		},
		setTotalStake(state, action) {
			state.totalStaked = action.payload;
		},
		setAvaxPrice(state, action) {
			state.avaxPrice = action.payload;
		},
		setUserDeposits(state,action){
			state.userDeposits = action.payload;
		},
		setLoaderValue(state,action){
			const {isLoaderOpen,loaderMessage} = action.payload;
			state.isLoaderOpen = isLoaderOpen;
			state.loaderMessage = loaderMessage;
		}
	},
});

export const {
	setWalletAddress,
	setPercentages,
	setUserData,
	setTotalStake,
	setAvaxPrice,
	setUserDeposits,
	setLoaderValue,
} = web3Slice.actions;
export default web3Slice.reducer;
