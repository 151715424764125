import React from 'react';
import { Container, Navbar, Nav, NavItem, NavLink, Collapse } from 'reactstrap';
import { configEnv } from '../utils/configEnv'

function Header() {
	return (
		<Container fluid className='header__container d-none d-md-block'>
			<Container>
				<Navbar expand='md' className='custom__header'>
					<Collapse navbar>
						<Nav navbar>
							<NavItem>
								<NavLink href='#dashboard'>DASHBOARD</NavLink>
							</NavItem>
							<NavItem className='mx-5'>
								<NavLink href='https://hazecrypto.net/audit/AvaxWorld' target="_blank">AUDIT</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href='https://t.me/+-HLhOWHmpNAyYTlh' target="_blank">TELEGRAM</NavLink>
							</NavItem>
							<NavItem className='mx-5'>
								<NavLink
									target='_blank'
									href={configEnv.AVAX_CONTRACT_ADDRESS_URL}
								>
									CONTRACT
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink 
									href='https://medium.com/@avaxfinance/avaxworld-finance-earn-up-to-20-daily-on-your-staked-avax-token-da4310d09f99'
									target="_blank"
								>DOCUMENTATION</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar>
			</Container>
		</Container>
	);
}

export default Header;
