import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { configEnv } from './configEnv';
import { web3Store } from '../store/web3Store';
import {
	setAvaxPrice,
	setLoaderValue,
	setPercentages,
	setTotalStake,
	setUserData,
	setUserDeposits,
	setWalletAddress,
} from '../store/web3Slice';
import AvaxWorld from './contract/avax-world.json';
import { getQueryVariable, tokenPriceAVAX } from './helper';
import { toast } from 'react-toastify';

export let web3 = new Web3(configEnv.AVAX_RPC);

export const connectWallet = async () => {
	try {
		const state = web3Store.getState();
		const address = state?.web3Reducer?.userAddress;
		if (!address) {
			console.log('Connecting to wallet');
			// /*
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						bridge: 'https://bridge.walletconnect.org',
						chainId: configEnv.AVAX_CHAINID, //137 for mainnet
						// network: configEnv.AVAX_NETWORK_ID, //matic for mainnet
						rpc: {
							// 43113: configEnv.AVAX_RPC,
							43114: configEnv.AVAX_RPC,
						},
					},
				},
			};

			console.log('Provider is', providerOptions);

			const web3Modal = new Web3Modal({
				// network: configEnv.AVAX_NETWORK_ID,
				cacheProvider: false, // optional
				providerOptions, // required
				disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
			});

			console.log('Web3Modal instance is', web3Modal);
			const provider = await web3Modal.connect();

			provider.on('accountsChanged', async accounts => {
				console.log('Accounts', accounts);
				web3Store.dispatch(setWalletAddress(accounts[0]));
				await getUserData();
				await getUserDeposits();
			});

			web3 = new Web3(provider);

			console.log('Web3 instance is', web3);

			const chainid = await web3.eth.getChainId();

			console.log(chainid);
			if (chainid !== configEnv.AVAX_CHAINID) {
				// notification("error", `Please connect to ${configEnv.AVAX_NETWORK_NAME}`);
				// alert(`Please connect to ${configEnv.AVAX_NETWORK_NAME}`);
				toast.error(`Please connect to ${configEnv.AVAX_NETWORK_NAME}`);
				return;
			}
			const accounts = await web3.eth.getAccounts();
			console.log('Acount is', accounts[0]);

			web3Store.dispatch(setWalletAddress(accounts[0]));
			await getUserData();
			await getUserDeposits();
		} else {
			console.log('Already connected');
		}
	} catch (err) {
		console.log(err);
		// alert(err.message);
		toast.warn(err.message);
	}
};

export const getContractInstance = async web3 => {
	const address = configEnv.CONTRACT_ADDRESS;
	return new Promise(resolve => {
		const contract = new web3.eth.Contract(AvaxWorld['abi'], address);
		resolve(contract);
	});
};

export const getPercentages = async () => {
	try {
		const avaxWorldContract = await getContractInstance(web3);

		const plan1Percent = await avaxWorldContract.methods
			.getPercent(0)
			.call();
		const plan1PercentValue = parseInt(plan1Percent) / 10;
		const plan2Percent = await avaxWorldContract.methods
			.getPercent(1)
			.call();
		const plan2PercentValue = parseInt(plan2Percent) / 10;
		const plan3Percent = await avaxWorldContract.methods
			.getPercent(2)
			.call();
		const plan3PercentValue = parseInt(plan3Percent) / 10;
		const plan4Percent = await avaxWorldContract.methods
			.getPercent(3)
			.call();
		const plan4PercentValue = parseInt(plan4Percent) / 10;
		web3Store.dispatch(
			setPercentages({
				plan1Percentage: plan1PercentValue,
				plan2Percentage: plan2PercentValue,
				plan3Percentage: plan3PercentValue,
				plan4Percentage: plan4PercentValue,
			})
		);
	} catch (err) {
		console.log(err);
	}
};

export const getContractData = async () => {
	try {
		const avaxWorldContract = await getContractInstance(web3);
		const totalStakedInWei = await avaxWorldContract.methods
			.totalStaked()
			.call();

		const totalStaked = parseFloat(
			web3.utils.fromWei(totalStakedInWei, 'ether')
		);

		web3Store.dispatch(setTotalStake(totalStaked));
	} catch (err) {
		console.log(err);
	}
};

export const investHandler = async (planType, value) => {
	try {
		const state = web3Store.getState();
		const userAddress = state?.web3Reducer?.userAddress;
		if (!userAddress) {
			// alert('Please connect your wallet');
			toast.warn('Please connect your wallet');
			return;
		}
		if (planType < 0 || planType > 3) {
			// alert('Invalid plan');
			toast.warn('Invalid plan');
			return;
		}
		web3Store.dispatch(
			setLoaderValue({
				isLoaderOpen: true,
				loaderMessage:
					'Please wait, we are processing your transaction',
			})
		);
		const avaxWorldContract = await getContractInstance(web3);

		const valueInWei = web3.utils.toWei(value.toString(), 'ether');

		let ref = getQueryVariable('ref');
		if (!web3.utils.isAddress(ref)) {
			ref = configEnv['REF_ADDRESS'];
		}

		const trx = await avaxWorldContract.methods.invest(ref, planType).send({
			from: userAddress,
			value: valueInWei,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		});
		console.log(trx);
		getGenaricData();
		getUserData();
		getUserDeposits();
		web3Store.dispatch(
			setLoaderValue({
				isLoaderOpen: false,
				loaderMessage: '',
			})
		);
		toast.success('Investment successful');

		// alert('trx successful', trx.transactionHash);
	} catch (err) {
		console.log(err);
		// alert(err.message);
		web3Store.dispatch(
			setLoaderValue({
				isLoaderOpen: false,
				loaderMessage: '',
			})
		);
		toast.warn(err.message);
	}
};

export const withdrawHandler = async () => {
	try {
		const state = web3Store.getState();
		const userAddress = state?.web3Reducer?.userAddress;
		if (!userAddress) {
			// alert('Please connect your wallet');
			toast.warn('Please connect your wallet');
			return;
		}
		web3Store.dispatch(
			setLoaderValue({
				isLoaderOpen: true,
				loaderMessage:
					'Please wait, we are processing your transaction',
			})
		);
		const avaxWorldContract = await getContractInstance(web3);

		const trx = await avaxWorldContract.methods.withdraw().send({
			from: userAddress,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		});

		getGenaricData();
		getUserData();
		// alert('trx successful', trx.transactionHash);
		web3Store.dispatch(
			setLoaderValue({
				isLoaderOpen: false,
				loaderMessage: '',
			})
		);
		toast.success('Withdraw successful');
	} catch (err) {
		console.log(err);
		// alert(err.message);
		web3Store.dispatch(
			setLoaderValue({
				isLoaderOpen: false,
				loaderMessage: '',
			})
		);
		toast.warn(err.message);
	}
};

export const getUserData = async () => {
	try {
		const priceInUsd = await tokenPriceAVAX();
		const state = web3Store.getState();
		const userAddress = state?.web3Reducer?.userAddress;
		if (!userAddress) {
			// alert('Please connect your wallet');
			return;
		}
		const avaxWorldContract = await getContractInstance(web3);

		const userData = await avaxWorldContract.methods
			.users(userAddress)
			.call();

		const userRefferals = await avaxWorldContract.methods
			.getUserDownlineCount(userAddress)
			.call();

		const checkpoint = parseInt(userData?.checkpoint) * 1000;

		const totalDeposits = parseFloat(
			web3.utils.fromWei(userData?.totalDeposits, 'ether')
		);
		const totalDepositsUsd = totalDeposits * priceInUsd;
		const totalWithdrawn = parseFloat(
			web3.utils.fromWei(userData?.totalWithdrawn, 'ether')
		);
		const totalWithdrawnUsd = totalWithdrawn * priceInUsd;
		const totalBonus = parseFloat(
			web3.utils.fromWei(userData?.totalBonus, 'ether')
		);
		const totalBonusUsd = totalBonus * priceInUsd;
		const bonusWithdrawn = parseFloat(
			web3.utils.fromWei(userData?.bonusWithdrawn, 'ether')
		);
		const bonusWithdrawnUsd = bonusWithdrawn * priceInUsd;
		const bonus = parseFloat(web3.utils.fromWei(userData?.bonus, 'ether'));
		const bonusUsd = bonus * priceInUsd;
		const timeStep = await avaxWorldContract.methods.TIME_STEP().call();
		let holdBonus;
		if (checkpoint) {
			const HOLD_BONUS = 0.1;
			const noOfDays =
				(Date.now() - checkpoint) / (parseInt(timeStep) * 1000);
			holdBonus = noOfDays * HOLD_BONUS;
			if (holdBonus < 0.1) {
				holdBonus = 0;
			}
			if (holdBonus > 1) {
				holdBonus = 1;
			}

			if (noOfDays >= 21) {
				holdBonus = 5;
			}
		}

		const userDividendsInWei = await avaxWorldContract.methods
			.getUserDividends(userAddress)
			.call();
		const userDividends = parseFloat(
			web3.utils.fromWei(userDividendsInWei, 'ether')
		);
		const userDividendsUsd = userDividends * priceInUsd;

		const userDataToStore = {
			totalDeposits,
			totalDepositsUsd,
			totalWithdrawn,
			totalWithdrawnUsd,
			totalBonus,
			totalBonusUsd,
			bonusWithdrawn,
			bonusWithdrawnUsd,
			bonus,
			bonusUsd,
			userRefferals,
			userDividends,
			userDividendsUsd,
			checkpoint,
			holdBonus,
		};

		await web3Store.dispatch(setUserData(userDataToStore));
	} catch (err) {
		console.log('Error in getUserData', err);
	}
};

export const getAvaxPriceData = async () => {
	try {
		const priceInUsd = await tokenPriceAVAX();
		await web3Store.dispatch(setAvaxPrice(priceInUsd));
	} catch (err) {
		console.log(err);
	}
};

export const getGenaricData = () => {
	try {
		getPercentages();
		getContractData();
	} catch (err) {
		console.log(err);
	}
};

export const getUserDeposits = async () => {
	try {
		const state = web3Store.getState();
		const userAddress = state?.web3Reducer?.userAddress;
		if (!userAddress) {
			// alert('Please connect your wallet');
			return;
		}
		const avaxWorldContract = await getContractInstance(web3);

		const totalUserDeposits = await avaxWorldContract.methods
			.getUserAmountOfDeposits(userAddress)
			.call();
		let userDepositPlans = [];
		for (let i = 0; i < totalUserDeposits; i++) {
			const usersDeposit = await avaxWorldContract.methods
				.getUserDepositInfo(userAddress, i)
				.call();
			const userDepositsObject = {
				plan: usersDeposit?.plan,
				percent: parseInt(usersDeposit?.percent) / 10,
				amount: parseFloat(
					web3.utils.fromWei(usersDeposit?.amount, 'ether')
				),
				profit: parseFloat(
					web3.utils.fromWei(usersDeposit?.profit, 'ether')
				),
				start: usersDeposit?.start * 1000,
				finish: usersDeposit?.finish * 1000,
			};
			userDepositPlans.push(userDepositsObject);
		}
		userDepositPlans = JSON.parse(JSON.stringify(userDepositPlans));

		await web3Store.dispatch(setUserDeposits(userDepositPlans));
	} catch (err) {
		console.log(err);
	}
};
