import { useEffect, useState } from 'react';
import Deposits from './component/Deposits';
import Earnings from './component/Earnings';
import Header from './component/Header';
import Hero from './component/Hero';
import Packages from './component/Packages';
import Referral from './component/Referral';
import TopNav from './component/TopNav';
import { getAvaxPriceData, getGenaricData } from './utils/web3-helper';
import { css } from '@emotion/react';
import DotLoader from 'react-spinners/DotLoader';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
const override = css`
	display: block;
	margin-bottom: 20px;
`;
function App() {
	const [color] = useState('#fb7800');
	const state = useSelector(state => state.web3Reducer);

	useEffect(() => {
		getGenaricData();
		// setCountDown(true);
	}, []);

	useEffect(() => {
		getAvaxPriceData();
	}, []);

	return (
		<div className='App'>
			<LoadingOverlay
				active={state?.isLoaderOpen}
				spinner={<DotLoader color={color} css={override} size={150} />}
				text={state?.loaderMessage}
			>
				<TopNav />
				<Header />
				<Hero />
				<Earnings />
				<Packages />
				<Referral />
				{state?.userDeposits?.length && <Deposits />}
			</LoadingOverlay>
		</div>
	);
}

export default App;