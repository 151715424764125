import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { withdrawHandler } from '../utils/web3-helper';

function Earnings() {
	const state = useSelector(state => state.web3Reducer);

	const withdrawOnclickHandler = async () => {
		await withdrawHandler();
	};

	return (
		<Container className='mt-5' id="dashboard">
			<h2 className='section__heading'>EARNINGS</h2>
			<hr className='underline__hr' />
			<Row className='mt-4'>
				<Col lg='8'>
					<Card className='withdraw__card py-2'>
						<CardBody>
							<Row className=' justify-content-around'>
								<Col md='4'>
									<Label className='withdraw__label'>
										Staked AVAX
									</Label>
									<p className='withdraw__number'>
										{state?.userData?.totalDeposits?.toFixed(
											2
										) || 0.0}
									</p>
								</Col>
								<Col md='4'>
									<Label className='withdraw__label'>
										Available AVAX for withdraw
									</Label>
									<p className='withdraw__number'>
										{state?.userData?.userDividends?.toFixed(
											2
										) || 0.0}
									</p>
								</Col>
								<Col md='4'>
									<Label className='withdraw__label'>
										Holding Bonus
									</Label>
									<p className='withdraw__number'>
										{state?.userData?.holdBonus?.toFixed(
											2
										) || 0.0}
										%
									</p>
								</Col>
							</Row>
							<div className='text-center'>
								<Button
									className='mt-3 btn__round px-5'
									onClick={withdrawOnclickHandler}
								>
									WITHDRAW
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col lg='4' className='mt-4 mt-lg-0'>
					<Card className='avax__price__card h-100'>
						<CardBody className='px-5'>
							<Label className='withdraw__label'>
								Avax Price
							</Label>
							<br />
							<Label className='withdraw__label fs-32 mt-2 fw-500'>
								${state?.avaxPrice.toFixed(2) || 0.0}
							</Label>
							<br />
							<br />
							<Label className='withdraw__label'>
								TOTAL STAKED AMOUNT
							</Label>
							<br />
							<Label className='withdraw__label fs-32 mt-2 fw-500'>
								{state?.totalStaked.toFixed(2) || 0.0} AVAX
							</Label>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Earnings;
