import React from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Label,
	Row,
} from 'reactstrap';
import Lock from '../assets/img/lock.png';
import UnLock from '../assets/img/unlock.png';
import Countdown from 'react-countdown';

function Deposits() {
	const state = useSelector(state=>state.web3Reducer);

	return (
		<Container className='mt-5 mb-5'>
			<h2 className='section__heading'>DEPOSITS</h2>
			<hr className='underline__hr' />
			<Row className='mt-4'>
				{
					state?.userDeposits?.length && state?.userDeposits?.map((userDepositData)=>{
						
						if(userDepositData?.plan==="0"){
							return (
								<Col lg='4' md='6'>
								<Card className={userDepositData?.finish<Date.now()?'package__card-disabled':'package__card'}>
									<CardHeader>
										<div className='d-flex'>
											<Label className='plan__label'>PLAN 1</Label>
											<img
												src={UnLock}
												alt='UnLock'
												height='30px'
												width='30px'
												className='ms-auto'
											/>
										</div>
									</CardHeader>
									<div className='text-center'>
										<Button className='custom__parent__round'>
											<Button className='custom__child__round'>
												<span className='days'>
													    <Countdown className='countdown' date={userDepositData?.finish}>
															<div>Expired</div>
														</Countdown>
												</span>
												
												<p className='days__desc'>
													Profit{' '}
													<span className='text__yellow'>
														{(userDepositData?.profit).toFixed(2) || 0.00}
													</span>
												</p>
											
											</Button>
										</Button>
									</div>
									<CardBody className='package__card__body'>
										<div className='d-flex'>
											<div>
												<Label className='withdraw__label fs-15 text-black'>
													Daily Earnings
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
													{userDepositData?.percent?.toFixed(2)}%
												</p>
											</div>
											<div className='ms-auto'>
												<Label className='withdraw__label fs-15 text-black'>
													Total ROI
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
												{(userDepositData?.percent?.toFixed(2) * 14).toFixed(2)}%
												</p>
											</div>
										</div>
										<Label className='withdraw__label mt-3 fs-15 text-black'>
											Invested AVAX: 
											<span className='text__yellow'>
												{(userDepositData?.amount).toFixed(2) || 0.00}
											</span>
										</Label>
									</CardBody>
								</Card>
								</Col>
							)
						}
						else if(userDepositData?.plan==="1"){
							return(
								<Col lg='4' md='6'>
								<Card className={userDepositData?.finish<Date.now()?'package__card-disabled':'package__card'}>
									<CardHeader>
										<div className='d-flex'>
											<Label className='plan__label'>PLAN 2</Label>
											<img
												src={Lock}
												alt='Lock'
												height='30px'
												width='30px'
												className='ms-auto'
											/>
										</div>
									</CardHeader>
									<div className='text-center'>
										<Button className='custom__parent__round'>
											<Button className='custom__child__round'>
												<span className='days'>
													    <Countdown date={userDepositData?.finish}>
															<div>Expired</div>
														</Countdown>
												</span>
												<p className='days__desc'>
													Profit{' '}
													<span className='text__yellow'>
														{(userDepositData?.profit).toFixed(2) || 0.00}
													</span>
												</p>
											
											</Button>
										</Button>
									</div>
									<CardBody className='package__card__body'>
										<div className='d-flex'>
											<div>
												<Label className='withdraw__label fs-15 text-black'>
													Daily Earnings
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
													{userDepositData?.percent}%
												</p>
											</div>
											<div className='ms-auto'>
												<Label className='withdraw__label fs-15 text-black'>
													Total ROI
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
													{(userDepositData?.percent?.toFixed(2) * 28).toFixed(2)}%
												</p>
											</div>
										</div>
										<Label className='withdraw__label mt-3 fs-15 text-black'>
											Invested AVAX: 
											<span className='text__yellow'>
												{(userDepositData?.amount).toFixed(2) || 0.00}
											</span>
										</Label>
									</CardBody>
								</Card>
								</Col>
								)
						}
						else if(userDepositData?.plan==="2"){
							return(
								<Col lg='4' md='6'>
								<Card className={userDepositData?.finish<Date.now()?'package__card-disabled':'package__card'}>
									<CardHeader>
										<div className='d-flex'>
											<Label className='plan__label'>PLAN 3</Label>
											<img
												src={Lock}
												alt='UnLock'
												height='30px'
												width='30px'
												className='ms-auto'
											/>
										</div>
									</CardHeader>
									<div className='text-center'>
										<Button className='custom__parent__round'>
											<Button className='custom__child__round'>
												<span className='days'>
													    <Countdown date={userDepositData?.finish}>
															<div>Expired</div>
														</Countdown>
												</span>
												<p className='days__desc'>
													Profit{' '}
													<span className='text__yellow'>
														{(userDepositData?.profit).toFixed(2) || 0.00}
													</span>
												</p>
											
											</Button>
										</Button>
									</div>
									<CardBody className='package__card__body'>
										<div className='d-flex'>
											<div>
												<Label className='withdraw__label fs-15 text-black'>
													Daily Earnings
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
													{userDepositData?.percent}%
												</p>
											</div>
											<div className='ms-auto'>
												<Label className='withdraw__label fs-15 text-black'>
													Total ROI
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
													{(userDepositData?.percent?.toFixed(2) * 14).toFixed(2)}%
												</p>
											</div>
										</div>
										<Label className='withdraw__label mt-3 fs-15 text-black'>
											Invested AVAX: 
											<span className='text__yellow'>
												{(userDepositData?.amount).toFixed(2) || 0.00}
											</span>
										</Label>
									</CardBody>
								</Card>
								</Col>
								)
						}
						else if(userDepositData?.plan==="3"){
							return(
								<Col lg='4' md='6'>
								<Card className={userDepositData?.finish<Date.now()?'package__card-disabled':'package__card'}>
									<CardHeader>
										<div className='d-flex'>
											<Label className='plan__label'>PLAN 4</Label>
											<img
												src={Lock}
												alt='Lock'
												height='30px'
												width='30px'
												className='ms-auto'
											/>
										</div>
									</CardHeader>
									<div className='text-center'>
										<Button className='custom__parent__round'>
											<Button className='custom__child__round'>
												<span className='days'>
													    <Countdown date={userDepositData?.finish}>
															<div>Expired</div>
														</Countdown>
												</span>
												<p className='days__desc'>
													Profit{' '}
													<span className='text__yellow'>
														{(userDepositData?.profit).toFixed(2) || 0.00}
													</span>
												</p>
											
											</Button>
										</Button>
									</div>
									<CardBody className='package__card__body'>
										<div className='d-flex'>
											<div>
												<Label className='withdraw__label fs-15 text-black'>
													Daily Earnings
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
													{userDepositData?.percent}%
												</p>
											</div>
											<div className='ms-auto'>
												<Label className='withdraw__label fs-15 text-black'>
													Total ROI
												</Label>
												<p className='text-black withdraw__label fs-24 fw-500'>
													{(userDepositData?.percent?.toFixed(2) * 28).toFixed(2)}%
												</p>
											</div>
										</div>
										<Label className='withdraw__label mt-3 fs-15 text-black'>
											Invested AVAX: 
											<span className='text__yellow'>
												{(userDepositData?.amount).toFixed(2) || 0.00}
											</span>
										</Label>
									</CardBody>
								</Card>
								</Col>
								)
						}else{
							return <></>
						}
					})
				}
			</Row>
		</Container>
	);
}

export default Deposits;
